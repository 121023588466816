import React from "react";
import Helmet from "react-helmet";
import Layout from "../components/layout";

import profile from "../assets/images/ozgur_2.png";

const About = props => (
  <Layout>
    <Helmet
      title="About Ozgur Mutlu"
      meta={[
        {
          name: "description",
          content:
            "Ozgur Mutlu, born in İzmir, 1974, is a Turkish musician and expert oud player. He has been playing oud since 1981. He has worked with many folk music groups and musicians in the city, as a performer and conductor."
        },
        { name: "keywords", content: "oud, music, lesson, online" }
      ]}
    ></Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Ozgur Mutlu</h1>
          </header>
          <span className="image main">
            <img src={profile} alt="" />
          </span>
          <p>
            Ozgur Mutlu, born in İzmir, 1974, is a Turkish musician and expert
            oud player. He has been playing oud since 1981. He has worked with
            many folk music groups and musicians in the city, as a performer and
            conductor.
          </p>
          <p>
            As an educator with over 29 years of professional teaching
            experience, Ozgur has been known to encourage the exploration of
            music and artistic practices of the various cultures represented in
            her classrooms. His repertoire includes music from Turkey, Egypt,
            Jordan, Iraq, Syria, and western music. As a music teacher, he
            enjoys sharing her own musical heritage with the students online.
          </p>
        </div>
      </section>
    </div>
  </Layout>
);

export default About;
